import styled from "styled-components"

import { SectionWrapper} from "../WebsitePage/styles";
import { colors } from "../../const/colors"
import Startup360 from "../../images/startup360.svg";
import DownArrows from "../../images/downarrows.svg";
import React from "react";
import Img from "gatsby-image"

export const OurServicesSection = styled(SectionWrapper)`
  background-color: ${colors.lightBg};
  .content {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    max-width:900px;
    margin: 50px auto;
  }
  .main-content {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    margin:50px auto;
    width:100%;
  }
  p {
    text-align: center;
  }
`
export const ServiceBoxWrapper = styled(({className,children}) => {
    return <div className={className}>
        {children}
    </div>
})`
    margin-top:50px;
    display:flex;
    justify-content: ${props => props.justifyContent ? props.justifyContent : "space-between"};
    flex-wrap:wrap;
    width:100%;
 `

export const ServiceBox = styled(({className, children, icon, title, subtitle}) => {
 return (
   <div className={className}>
    <div className="icon">
        {icon.base64 ?  <Img fluid={icon} /> : <img src={icon} alt="startup"/>}
     {/*<img src={icon} alt="startup"/>*/}

    </div>
    <span className="title">{title}</span>
    <span className="subtitle">{subtitle}</span>
    <img className="down-arrow" src={DownArrows} alt=""/>
    <div className="box-main-content">
     {children}
    {/* <h5>Branding</h5>*/}
    {/* <span>Brand guidelines</span>*/}
    {/* <span>Print material</span>*/}
    {/* <span>Digital content</span>*/}
    {/* <h5>Digital Set up</h5>*/}
    {/* <span>Domain</span>*/}
    {/* <span>Web Hosting</span>*/}
    {/* <span>Email Hosting</span>*/}
    {/* <span>Social media accounts</span>*/}
    {/* <h5>Development</h5>*/}
    {/* <span>Web / mobile solution</span>*/}
    {/* <span>CMS integration (options)</span>*/}
    </div>
   </div>
 )
})`
  width:100%;
  padding:30px;
 background:white;
  margin-bottom:15px;
  @media screen and (min-width: 768px) {
    width:48%;
    margin-right:${props => props.marginRight ? props.marginRight : "0px"};
  }
  @media screen and (min-width: 992px) {
    width:32%;
  }
 .icon {
    width:100%;
    max-width: ${ props => props.iconWidth ? props.iconWidth : "65px"};
  margin:auto;
    img {
     display: block;
     width:100%;
      margin-bottom: ${props => props.iconBottomSpace ? props.iconBottomSpace : "1.45rem"}
    }
 }
 span {
  display:block;
  text-align: center;
  margin-bottom:10px;
 }
 .title {
  font-weight: bold;
  color: ${colors.grayColor};
 }
 .down-arrow {
  display: block;
  margin:10px auto;
  width:30px;
  
 }
 .box-main-content {
    text-align: center;
    h5 {
     margin:20px auto 10px auto;
    }
    span {
     font-size:.90rem;
    }
 }
`
