import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/Container"
import {SectionTitle} from "../components/SectionTitle/styles"
import ConsistentIcon from "../images/consistent_marketing.svg"
import EngagingIcon from "../images/engaging.svg"
import RelevantIcon from "../images/target.svg"
import CirclesFour from "../images/circlesfour.svg"
import NotesIcon from "../images/notes.svg"
import ColorPaletteIcon from "../images/colorpalette.svg"
import {ButtonGf, ButtonLink, ButtonWrapper, PopupText} from "../components/Button/styles";
import { colors } from "../const/colors"
import {IoCheckmark} from "react-icons/io5"
import {InfoBox} from "../components/Footer/styles";
import {BannerSection, BenefitsSection, PracticesSection} from "../components/WebsitePage/styles";
import CircleTwo from "../images/greyCirclesLeft.svg";
import CircleThree from "../images/grayCirclesRight.svg";
import { BannerStyles} from "../const/inline_styles";
import { OurServicesSection } from "../components/PackagesPage/styles";
import {ServiceBox, ServiceBoxWrapper} from "../components/PackagesPage/styles";
import { ServiceBoxWrapper as HomeServiceBoxWrapper, ServiceBox as HomeServiceBox } from "../components/HomePage/styles"
import ImageAccordion from "../components/ImageAccordion";
import DollarSmall from "../images/dollar.svg";
import DollarBig from "../images/dollarBig.svg";
import CommentSmall from "../images/comment.svg";
import CommentBig from "../images/commentBig.svg";
import PenSmall from "../images/pen.svg";
import PenBig from "../images/penBig.svg";
import CalendarSmall from "../images/calendar.svg"
import CalendarBig from "../images/calendarBig.svg"
import {SocialMediaSection} from "../components/MarketingPage/styles";
import StrategyIcon from "../images/socialHorse.svg"
import VisualRecIcon from "../images/socialRiborn.svg"
import MicIcon from "../images/socialMic.svg"
import PluginIcon from "../images/socialPlug.svg"
import PaidIcon from "../images/socialTv.svg"
import OptimizeIcon from "../images/socialTool.svg"

import localData from "../localization/marketing.json"

const accordionItems = [
    {
        id:1,
        title: {
            en: "Buyer personas",
            es: "Modelos de los compradores"
        },
        content: {
            en: "We create a detailed description of your ideal customer",
            es: "Creamos una descripción detallada de tu cliente ideal"
        },
        icon: {
            name: DollarSmall
        },
        image: DollarBig,
        open: true,
    },
    {
        id:2,
        title: {
            en: "Topic Research",
            es: "Investigación del tema"
        },
        content: {
            en: "We identify general topics of interest to your audience",
            es: "Identificamos temas generales de interés para tu audiencia"
        },
        icon: {
            name: CommentSmall,
            size: "24px"
        },
        image: CommentBig,
        open: false,
    },
    {
        id:3,
        title: {
            en: "Editorial Workflow",
            es: "Flujo de trabajo editorial"
        },
        content: {
            en: "We design the process for generating desired content, together with your team",
            es: "Diseñamos el proceso para generar el contenido deseado, junto con tu equipo"
        },
        icon: {
            name: PenSmall,
            size: "24px"
        },
        image: PenBig,
        open: false,
    },
    {
        id:4,
        title: {
            en: "Editorial Calendar",
            es: "Calendario editorial"
        },
        content: {
            en: "We create the publishing schedule specifying content type and channel for distribution",
            es: "Creamos el cronograma de publicación especificando el tipo de contenido y el canal de distribución"
        },
        icon: {
            name: CalendarSmall,
            size: "24px"
        },
        image: CalendarBig,
        open: false,
    }
]

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const GfMarketing = ({data, location, pageContext}) => {
    const { lang } = pageContext
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    return <Layout location={location} lang={lang} flags={flags}>
        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <BannerSection>
            <Container>
                <h2 style={BannerStyles.h2}>{localData.pageTitle[lang]}</h2>
                <SectionTitle>
                    {localData.sections.hero.title[lang]}
                </SectionTitle>
                <p style={{maxWidth:"900px"}}>{localData.sections.hero.description[lang]}</p>
                <div className="info-box-list">
                    <InfoBox space="7px" step={1} icon={<img src={RelevantIcon} alt="consistent" />} text={localData.sections.hero.group.first[lang]} color={colors.grayColor} />
                    <InfoBox space="7px" step={2} size="43px" icon={<img src={EngagingIcon} alt="recognizable" />} text={localData.sections.hero.group.second[lang]} color={colors.grayColor} />
                    <InfoBox space="7px" step={3} icon={<img src={ConsistentIcon} alt="focused" />} text={localData.sections.hero.group.third[lang]} color={colors.grayColor} />
                </div>
                <div className="free-consultation">
                    <PopupText text={localData.sections.hero.button.label[lang]} url="https://calendly.com/tina-greenflamingo/30min" />
                </div>
            </Container>
            <div className="bg-image">
                <img src={CirclesFour} alt="circles"/>
            </div>
        </BannerSection>
        <BenefitsSection>
            <Container>
                <SectionTitle align="center">{localData.sections.benefits.title[lang]}</SectionTitle>
                <div className="content">
                    <p style={{textAlign:"center"}}>{localData.sections.benefits.description[lang]}</p>

                    <ul>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.first[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.second[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.third[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fourth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fifth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.sixth[lang]}} /></li>
                    </ul>
                    <div className="get-in-touch">
                        <h4>{localData.sections.benefits.subTitle[lang]}</h4>
                        <span>{localData.sections.benefits.subDescription[lang]}</span>
                        {/*<ButtonGf>Start</ButtonGf>*/}
                        <div className="free-consultation free-consultation--flex">
                            <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={localData.sections.benefits.button.label[lang]} />
                        </div>
                    </div>
                </div>
            </Container>
            <img className="left" src={CircleTwo} alt="circles two"/>
            <img className="right" src={CircleThree} alt="circles three"/>
        </BenefitsSection>
        <OurServicesSection>
            <Container>
                <SectionTitle align="center">{localData.sections.quality.title[lang]}</SectionTitle>
                <p>{localData.sections.quality.description[lang]}</p>
                <ServiceBoxWrapper justifyContent="center">
                    <ServiceBox marginRight="25px" icon={NotesIcon} title={localData.sections.quality.group.first.title[lang]}>
                            <span>{localData.sections.quality.group.first.items.first[lang]}</span>
                            <span>{localData.sections.quality.group.first.items.second[lang]}</span>
                            <span>{localData.sections.quality.group.first.items.third[lang]}</span>
                    </ServiceBox>
                    <ServiceBox iconWidth="76px" icon={ColorPaletteIcon} title={localData.sections.quality.group.second.title[lang]}>
                        <span>{localData.sections.quality.group.second.items.first[lang]}</span>
                        <span>{localData.sections.quality.group.second.items.second[lang]}</span>
                        <span>{localData.sections.quality.group.second.items.third[lang]}</span>
                    </ServiceBox>
                </ServiceBoxWrapper>
                <br/>
                <ButtonWrapper align="center">
                    <ButtonLink to={localData.sections.quality.button.uri[lang]} color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{localData.sections.quality.button.label[lang]}</ButtonLink>
                </ButtonWrapper>
            </Container>
        </OurServicesSection>
        <PracticesSection>
            <Container>
                <SectionTitle align="center">{localData.sections.story.title[lang]}</SectionTitle>
                <ImageAccordion lang={lang} height="auto" accordionItems={accordionItems} />
            </Container>
        </PracticesSection>
        <SocialMediaSection>
            <Container>
                <SectionTitle align="center">{localData.sections.social.title[lang]}</SectionTitle>
                <p>{localData.sections.social.description[lang]}</p>
                <HomeServiceBoxWrapper>
                    <HomeServiceBox iconWidth="100px" img={StrategyIcon} title={localData.sections.social.group.first.title[lang]}>
                        <p>{localData.sections.social.group.first.content[lang]}</p>
                    </HomeServiceBox>
                    <HomeServiceBox img={VisualRecIcon} title={localData.sections.social.group.second.title[lang]}>
                        <p>{localData.sections.social.group.second.content[lang]}</p>
                    </HomeServiceBox>
                    <HomeServiceBox iconWidth="80px" img={MicIcon} title={localData.sections.social.group.third.title[lang]}>
                        <p>{localData.sections.social.group.third.content[lang]}</p>
                    </HomeServiceBox>
                    <HomeServiceBox img={PluginIcon} title={localData.sections.social.group.fourth.title[lang]}>
                        <p>{localData.sections.social.group.fourth.content[lang]}</p>
                    </HomeServiceBox>
                    <HomeServiceBox iconWidth="107px" img={PaidIcon} title={localData.sections.social.group.fifth.title[lang]}>
                        <p>{localData.sections.social.group.fifth.content[lang]}</p>
                    </HomeServiceBox>
                    <HomeServiceBox img={OptimizeIcon} title={localData.sections.social.group.sixth.title[lang]}>
                        <p>{localData.sections.social.group.sixth.content[lang]}</p>
                    </HomeServiceBox>
                </HomeServiceBoxWrapper>
            </Container>
        </SocialMediaSection>
    </Layout>
}


export default GfMarketing
